import { StaticPage } from '@dreamstack/accentro-contentful'
import { DreamstackSeo } from '@dreamstack/seo'
import 'twin.macro'
import { defaultQueries } from '../lib/defaultQueries'
import { getStaticPropsPlus } from '../lib/getStaticPropsPlus'

export default function Page() {
  return (
    <>
      <DreamstackSeo nofollow={true} noindex={true} />
      <StaticPage slug={'404'} />
    </>
  )
}

export const getStaticProps = getStaticPropsPlus({
  queries: defaultQueries,
})
